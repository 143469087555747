.container {
  width: 610px;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
}

.tag {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 8px 12px;
  margin-right: 12px;
  margin-top: 8px;
  border: 1px solid #4F4F4F;
  box-sizing: border-box;
  border-radius: 100px;
  color: #4F4F4F;
  background-color: #FFFFFF;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
}

.tag_active {
  color: #FFFFFF;
  background-color: #4F4F4F;
}
