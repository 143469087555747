.container {
  display: flex;
  flex-direction: column;
}

.title {
  margin: 4px 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #4F4F4F;
}

.images_container {
  position: relative;
  display: flex;
  width: 340px;
  height: 340px;
  border-bottom: 1px solid #4F4F4F;
  box-sizing: border-box;
}

.image {
  width: 340px;
  height: 340px;
}

.arrow_button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
  width: 32px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #4F4F4F;
  box-sizing: border-box;
  border-radius: 50px;
  cursor: pointer;
}

.arrow_img {
  width: 7.2px;
  height: 11.98px;
}

.arrow_left {
  left: 0;
}

.arrow_right {
  right: 0;
}

.dots_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.dot {
  width: 20px;
  height: 20px;
  background: #FFFFFF;
  border: 1px solid #4F4F4F;
  box-sizing: border-box;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 6.5px;
  margin-left: 6.5px;
}

.active_dot {
  background: #4F4F4F;
}

.value_container {
  padding: 10px;
  background: #FFFFFF;
  border-top: 1px solid #4F4F4F;
  border-bottom: 1px solid #4F4F4F;
  box-sizing: border-box;
}

.value {
  margin-top: 0;
  margin-bottom: 0;
  white-space: pre-wrap;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #4F4F4F;
}
