.container {

}

.current_container {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}

.current_title {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #4F4F4F;
}

.current_city {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  padding: 8px 12px 7px;
  background: #4F4F4F;
  border: 1px solid #4F4F4F;
  box-sizing: border-box;
  border-radius: 100px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}

.detail_text {
  width: 610px;
  height: 205px;
  padding: 6px 8px;
  background: #FFFFFF;
  border: 1px solid #4F4F4F;
  box-sizing: border-box;
  box-shadow: inset 0 2px 0 rgba(225, 228, 232, 0.2);
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #4F4F4F;
}

.input_title {
  margin-top: 20px;
  margin-bottom: 12px;
  margin-right: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #4F4F4F;
}

.input_container {
  margin-top: 12px;
}
