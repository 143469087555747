.container {
  position: relative;
  width: 160px;
  height: 160px;
  background: #FFFFFF;
  border: none;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
}

.icon_cancel {
  position: absolute;
  top: 6px;
  right: 3px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.image {
  display: flex;
  width: 160px;
  height: 160px;
  border: none;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  background-color: gray;
}
