.container {
  display: flex;
  flex-direction: row;
  width: 250px;
  height: 40px;
  padding: 4px;
  margin-top: 12px;
  background-color: #FFFFFF;
  border: 1px solid #4F4F4F;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
}

.text {
  flex: 1;
  text-align: left;
  padding: 6px;
  margin: 0;

  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: #4F4F4F;
  background-color: #FFFFFF;
}

.bar {
  margin-top: 4px;
  margin-bottom: 4px;
  border: 1px solid #4F4F4F;
}

.icon {
  width: 24px;
  height: 24px;
  margin: 3px 10px 3px 14px;
}
