.container {
  display: flex;
  flex-direction: column;
}

.tabs_container {
  display: flex;
  flex-direction: row;
  background: #FFFFFF;
  border-top: 1px solid #4F4F4F;
  border-bottom: 1px solid #4F4F4F;
  box-sizing: border-box;
}

.tab {
  padding: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  color: #4F4F4F;
  border-right: 1px solid #4F4F4F;
  box-sizing: border-box;
  cursor: pointer;
}

.active_tab {
  color: #FFFFFF;
  background: #4F4F4F;
}
