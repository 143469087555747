.container {
  margin-top: 12px
}

.textarea {
  width: 520px;
  height: 105px;
  padding: 8px;

  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #4F4F4F;

  background: #FFFFFF;
  border: 1px solid #4F4F4F;
  box-sizing: border-box;
  border-radius: 5px;
}

.error {
  border-color: #F75861;
}
