.container {
  display: flex;
  flex-direction: column;
}

.textarea {
  width: 160px;
  height: 96px;
  padding: 8px;
  margin-top: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #4F4F4F;
  background: #FFFFFF;
  border: 1px solid #4F4F4F;
  box-sizing: border-box;
  border-radius: 5px;
}
