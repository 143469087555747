.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 38px 26.5px;
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #54595E;
}

.content {
  margin-top: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #4F4F4F;
  text-align: center;
}

.buttons_container {
  display: flex;
  flex-direction: row;
}

.button {
  width: 180px;
  height: 44px;
  margin-right: 14px;
  margin-left: 14px;
  border: 1px solid #4F4F4F;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
}

.white {
  color: #4F4F4F;
  background: #FFFFFF;
}

.dark {
  color: #E5E5E5;
  background: #4F4F4F;
}
