.container {
  display: flex;
  width: 1350px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.contents_container {
  flex: 1;
  margin-left: 270px;
  margin-bottom: 40px;
  padding-left: 10px;
}

.previews_container {
  flex: 1;
  margin-left: 111px;
}

.short_preview_container {
  margin-bottom: 196px;
}

.preview_container {
  margin-bottom: 40px;
}
