body {
  margin: 0;
}

.react-datepicker-wrapper {
  display: flex;
}

.react-datepicker__input-container {
  display: flex;
}
