.container {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.text {
  margin-top: auto;
  margin-bottom: auto;

  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #4F4F4F;
}

.error_text {
  color: #F75861;
}
