.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #4F4F4F;
  background-color: #FFFFFF;
}

.container:hover {
  background-color: #F5F5F5;
}

.icon {
  margin-left: auto;
  margin-right: 16px;
}
