.container {
  width: 610px;
  padding-bottom: 8px;
  margin-bottom: 20px;

  border-bottom: 1px solid #000000;
}

.title {
  margin: 0;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #4F4F4F;
}

.description {
  margin: 8px 0 0;

  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}
