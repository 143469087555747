.container {
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100%;
  width: 270px;
  background-color: #FFFFFF;
}

.item {
  display: flex;
  align-items: center;
  height: 65px;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 35px;

  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #4F4F4F;
  background-color: #FFFFFF;
  cursor: pointer;
}

.item_active {
  color: #FFFFFF;
  background-color: #4F4F4F;
  cursor: default;
}
