.container {
  position: relative;
}

.close_button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  background: #E5E5E5;
  border-radius: 50px;
  cursor: pointer;
}

.close_icon {
  width: 10px;
  height: 10px;
}
