.container {
  display: flex;
  flex-direction: row;
  height: 40px;
}

.select_city_container {
  display: flex;
  width: 305px;
  flex-direction: row;
}

.select_city {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  margin-top: auto;
  margin-bottom: auto;
  padding: 8px 12px 7px;
  background: #4F4F4F;
  border: 1px solid #4F4F4F;
  box-sizing: border-box;
  border-radius: 100px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}

.day_container {
  display: flex;
  width: 305px;
  padding-left: 54px;
}

.lodging_button {
  width: 32px;
  height: 32px;
  margin-top: auto;
  margin-bottom: auto;
}

.day_count {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 40px;
  margin: 0 8px;
  background: #FFFFFF;
  border: 1px solid #4F4F4F;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: #000000;
}

.lodging_cancel {
  width: 50px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 36px;
  background: #4F4F4F;
  border: 1px solid #4F4F4F;
  box-sizing: border-box;
  border-radius: 100px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}
