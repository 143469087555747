.container {
  display: flex;
  flex-direction: column;
  width: 250px;
}

.image_container {
  position: relative;
  width: 250px;
  height: 250px;
  margin-bottom: 4px;
  border: 1px solid #4F4F4F;
  box-sizing: border-box;
  border-radius: 5px;
}

.new_icon {
  position: absolute;
  display: inline-block;
  padding: 2px 4px;
  margin-left: 8px;
  margin-top: 8px;
  margin-bottom: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #FFFFFF;
  background: #F75861;
  border: 1px solid #F75861;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: default;
}

.project_img {
  width: 250px;
  height: 250px;
  box-sizing: border-box;
  border-radius: 5px;
}

.content_container {
  display: flex;
  flex-direction: column;
  padding: 4px;
  background: #FFFFFF;
  border: 1px solid #4F4F4F;
  box-sizing: border-box;
  border-radius: 5px;
}

.tags_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.tag {
  display: inline-block;
  padding: 2px 4px;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #4F4F4F;
  background-color: white;
  border: 1px solid #4F4F4F;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: default;
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  max-height: 34px;
  color: #4F4F4F;
}

.host_container {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.host_image {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.no_host_image {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #C4C4C4;
}

.host_name {
  margin-left: 4px;
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #4F4F4F;
}

.keywords_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 4px;
  margin-bottom: 4px;
}

.keyword {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #4F4F4F;
}

.deadline {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #F75861;
}
