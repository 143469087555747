.container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #4F4F4F;
}

.tags_container {
  margin-top: 20px;
  margin-bottom: 13px;
}
