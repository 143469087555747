.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 40px 36px;
}

.check_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 28px;
  width: 40px;
  height: 40px;
  background: #FFFFFF;
  border: 4px solid #4F4F4F;
  border-radius: 50px;
}

.check {
  width: 16px;
  height: 18px;
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #54595E;
}

.content {
  margin-top: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: rgba(84, 89, 94, 0.6);
  text-align: center;
}

.buttons_container {
  display: flex;
  flex-direction: column;
}

.button {
  margin-top: 16px;
  width: 385px;
  height: 44px;
  border: 1px solid #4F4F4F;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
}

.white {
  color: #4F4F4F;
  background: #FFFFFF;
}

.dark {
  color: #E5E5E5;
  background: #4F4F4F;
}

