.container {
  z-index: 4;
  position: sticky;
  display: flex;
  top: 0;
  height: 50px;
  padding: 5px 8px;
  border-bottom: 1px solid #4F4F4F;
  background-color: #FFFFFF;
}

.button {
  margin-left: auto;
  padding: 8px 28px;
  border: 1px solid #4F4F4F;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #FFFFFF;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}
