.container {
  display: flex;
  flex-direction: row;
  width: 610px;
}

.icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.right_icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: auto;
}

.disable_icon {
  opacity: 20%;
  cursor: auto;
}

.tags_container {
  display: flex;
  flex-direction: row;
  overflow: auto;
  white-space: nowrap;
  padding-left: 8px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tags_container::-webkit-scrollbar {
  display: none;
}

.tag {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  padding: 9px 8px 8px;
  height: 33px;
  background: #FFFFFF;
  border: 1px solid #4F4F4F;
  box-sizing: border-box;
  cursor: pointer;
}

.tag_active {
  color: #FFFFFF;
  background: #4F4F4F;
}
