.container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #4F4F4F;
}

.tags_container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.type_container {

}

.sub_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #4F4F4F;
  margin-bottom: 6px;
}

.types_container {
  margin-bottom: 32px;
}

.input_container {
  padding-top: 6px;
}

.inputs_container {
  margin-top: 20px;
}
