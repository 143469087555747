.container {
  margin-bottom: 32px;
}

.title {
  margin: 0 0 12px;

  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #4F4F4F;
}
