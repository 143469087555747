.container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #4F4F4F;
}

.city_container {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}

.divider {
  width: 610px;
  height: 0;
  margin-top: 18px;
  margin-bottom: 8px;
  border: 1px solid #000000;
}

.result_container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 610px;
}

.result_city {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: #4F4F4F;
}

.result_day {
  position: absolute;
  margin-top: 0;
  margin-bottom: 0;
  right: 103px;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: #4F4F4F;
}

.add_schedule_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  width: 610px;
  height: 44px;
  background: #4F4F4F;
  cursor: pointer;
}

.add_schedule_icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.add_schedule_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: #FFFFFF;
}
