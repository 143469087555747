.container {
  display: flex;
  flex-direction: row;
  margin-top: 13px;
}

.input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 40px;

  border: 1px solid #4F4F4F;
  box-sizing: border-box;
  border-radius: 8px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #4F4F4F;
  background-color: #FFFFFF;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input_center {
  margin: auto 5px;
}
