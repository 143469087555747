.input {
  display: flex;
  align-items: center;
  width: 610px;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;

  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #4F4F4F;
  background: #FFFFFF;

  border: 1px solid #4F4F4F;
  box-sizing: border-box;
  border-radius: 6px;
}
