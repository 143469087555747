.button {
  margin-right: 8px;
  padding: 8px 20px;
  border: 1px solid #4F4F4F;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #FFFFFF;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #4F4F4F;
  cursor: pointer;
}

.button:disabled {
  color: #FFFFFF;
  background-color: #4F4F4F;
  cursor: default;
}
